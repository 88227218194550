
import Vue from "vue";
import { tableColumns } from "./listViewColumns";
import * as selectOptions from "@/helpers/selectOptions";
import {
  propertyanalyticsMapState,
  propertyanalyticsMapActions,
  propertyanalyticsMapGetters
} from "@/store/modules/propertyanalytics";
import NoResultsFound from "@/components/NotFound/NoResultsFound.vue";

interface IQuotesData {
  tableActions: any[];
  tableColumns: any;
  errorMessage: string;
  isLoading: boolean;
  isAdmin: boolean;
  loading: boolean;
  successMessage: string;
}
export default Vue.extend({
  name: "property-analytics",
  components: {
    NoResultsFound
  },
  data(): IQuotesData {
    return {
      successMessage: "",
      errorMessage: "",
      isLoading: false,
      isAdmin: true,
      tableActions: [],
      tableColumns: tableColumns,
      loading: false
    };
  },
  methods: {
    ...propertyanalyticsMapActions(["getPropertyanalyticss"]),
    async fetchPageData(queryObject: any): Promise<any> {
      try {
        this.loading = true;
        await this.getPropertyanalyticss({
          query: { ...queryObject }
        });
      } catch (error) {
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
      }
    },
    async viewCallback(dataReceived: any): Promise<any> {
      const {
        _id,
        ratingValidations,
        ratings,
        status,
        isLegacy
      } = dataReceived.quote;
      const { hasSelectedRating, hasRiskAddress } = ratingValidations;
      // redirect to view page if status is submitted
      if (status === "submitted" || status === "received" || isLegacy) {
        return this.$router.push(`/quotes/${_id}/view`);
      }

      if (hasSelectedRating || (ratings && ratings.length > 0)) {
        return this.$router.push(`/quotes/${_id}/property-rating`);
      } else if (hasRiskAddress) {
        return this.$router.push(`/quotes/${_id}/territory`);
      } else {
        this.$router.push(`/quotes/${_id}`);
      }
    },
    async onToolbarItemClick(event: string) {
      this.$router.push({
        name: "quotes",
        params: { preAction: event }
      });
    }
  },
  computed: {
    ...propertyanalyticsMapGetters(["getTotalRecords"]),
    ...propertyanalyticsMapState([
      "propertyanalytics",
      "links",
      "makingApiRequest"
    ]),
    tableRowActionsData(): any {
      return [];
    },
    topButtonSecondaryItems(): {
      text: string;
      key: string;
      subItems: { title: string; command: string }[];
    } | null {
      const options = [
        {
          title: "Quote Counter",
          command: "quote-counter"
        },
        {
          title: "Quote Report Generation",
          command: "quote-report-generation"
        },
        {
          title: "Quotes County Report",
          command: "quote-county"
        },
        {
          title: "Quotes Status Report",
          command: "quote-status"
        },
        {
          title: "Quotes Agent Report",
          command: "quote-agents"
        },
        {
          title: "Quotes",
          command: "quote"
        },
        {
          title: "Trash Bin",
          command: "quotes-deleted"
        },
        {
          title: "Legacy Quotes",
          command: "quotes-legacy"
        }
      ];

      if (this.$getCurrentUser?.role === "admin") {
        return {
          text: "Actions",
          key: "actions",
          subItems: options
        };
      }
      return null;
    },
    filters(): any {
      return [
        {
          field: "policyType",
          title: "Policy Type",
          options: selectOptions.companyLineOfBusiness
        },
        {
          field: "status",
          title: "Submission Status",
          options: [
            {
              label: "Draft",
              value: "draft"
            },
            {
              label: "Not Submitted",
              value: "not-submitted"
            },
            {
              label: "Rating-completed",
              value: "rating-completed"
            },
            {
              label: "Rating-in-progress",
              value: "rating-in-progress"
            },
            {
              label: "Rating-selected",
              value: "rating-selected"
            },
            {
              label: "Received",
              value: "received"
            },
            {
              label: "Rejected",
              value: "rejected"
            },
            {
              label: "Submitted",
              value: "submitted"
            }
          ]
        },
        {
          field: "submittedOn",
          title: "Date Submitted",
          isDate: true
        }
      ];
    }
  }
});
